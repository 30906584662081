import React, { ReactElement, FC } from "react"
import moment from "moment"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const PostTemplate = ({ data }): ReactElement => {
  const page = usePage(data)

  return (
    <Layout>
      <Seo title={page.title} />
      <div className="blog-page">
        <h2 className="text-3xl font-bold pb-2">{page.title}</h2>
        {/* <div className="text-xs pb-4">
          {page.date && `Dátum: ${moment(page.date).format("DD.MM.YYYY")}`}
        </div> */}
        <div className="flex flex-wrap flex-row">
          {/* <div className="flex-none w-full sm:w-32 md:w-64">
            <Img fluid={page.fluid} className="" />
          </div> */}
          <div
            className="flex-1 sm:ml-3"
            dangerouslySetInnerHTML={{ __html: page.html }}
          ></div>
        </div>
      </div>
    </Layout>
  )
}

export default PostTemplate

const usePage = (data: any) => ({
  title: data.wordpressPage.title,
  date: data.wordpressPage.date,
  html: data.wordpressPage.content,
  // fluid: data.wordpressPost.featured_media.localFile.childImageSharp.fluid, //does not work
  //   date: data.markdownRemark.frontmatter.date,
  //   path: data.markdownRemark.frontmatter.path,
  //   category: data.markdownRemark.frontmatter.category,
  //   download: data.markdownRemark.frontmatter.download,
  //   categoryName: categoryName(data.markdownRemark.frontmatter.category),
  //   fluid: data.markdownRemark.frontmatter.featuredImage.childImageSharp.fluid,
  //   fixed: data.markdownRemark.frontmatter.featuredImage.childImageSharp.fixed,
})

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
      content
    }
  }
`
